import React from 'react';
import Img from '../atom/img';

const PictureViewer = ({ src }: { src: any }) => {
  if (src)
    return (
      <div className="relative overflow-hidden h-32 w-32 min-w-32 rounded-full border-2 border-white shadow-img">
        <Img src={src} fill alt="captured" className="object-cover" />
      </div>
    );
  return (
    <div className="shadow-img bg-gray-brand4 flex items-center justify-center h-32 w-32 min-w-32 rounded-full">
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.001 13.9993C40.5777 13.9993 39.271 13.1827 38.6177 11.9227L36.9377 8.53935C35.8644 6.41602 33.0644 4.66602 30.6844 4.66602H25.341C22.9377 4.66602 20.1377 6.41602 19.0644 8.53935L17.3844 11.9227C16.731 13.1827 15.4244 13.9993 14.001 13.9993C8.93771 13.9993 4.92438 18.2693 5.25104 23.3093L6.46438 42.5827C6.74438 47.3894 9.33438 51.3327 15.7744 51.3327H40.2277C46.6677 51.3327 49.2344 47.3894 49.5377 42.5827L50.751 23.3093C51.0777 18.2693 47.0644 13.9993 42.001 13.9993ZM24.501 16.916H31.501C32.4577 16.916 33.251 17.7093 33.251 18.666C33.251 19.6227 32.4577 20.416 31.501 20.416H24.501C23.5444 20.416 22.751 19.6227 22.751 18.666C22.751 17.7093 23.5444 16.916 24.501 16.916ZM28.001 42.2794C23.661 42.2794 20.1144 38.756 20.1144 34.3927C20.1144 30.0294 23.6377 26.506 28.001 26.506C32.3644 26.506 35.8877 30.0294 35.8877 34.3927C35.8877 38.756 32.341 42.2794 28.001 42.2794Z"
          fill="#BFB6C3"
        />
      </svg>
    </div>
  );
};

export default PictureViewer;
