import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { WALLET_ENDPOINTS } from '@/constants/apiPath';
import { encryptPin } from '@/lib/encrypt';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const mutationFn = async ({ pin, isTransaction }: { pin: string; isTransaction?: boolean }) => {
  try {
    const endpoint = isTransaction
      ? WALLET_ENDPOINTS.createTransactionPin
      : WALLET_ENDPOINTS.createWalletPin;
    const res = await axiosClientInstance.post(
      endpoint,
      { pin: encryptPin(pin), enableBiometricTransafer: false },
      {
        baseURL: FUSION_API.apiMicroServiceWallet,
      }
    );
    return res.data;
  } catch (err: any) {
    return handleError(err as AxiosError<ErrorPayload>);
  }
};

const useCreateWalletPinMutation = () => {
  return useMutation({
    mutationFn,
  });
};

export default useCreateWalletPinMutation;
