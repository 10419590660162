import React from 'react';
import ModalNew from '../atom/modal-new';
import { buttonVariants } from '../atom/button';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

const failedFields = ['First name', 'Date of birth', 'Gender'];

const IdentityValidationFailedModal = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const open = searchParams.get('wallet-activation') === 'successful';
  const idType = searchParams.get('idType');

  return (
    <ModalNew
      isOpen={open}
      onClose={() => router.replace(pathname)}
      title=""
      description=""
      classNames={{ content: 'w-full max-w-[534px]' }}
    >
      <div className="w-full max-w-[340px] flex flex-col gap-6 items-center justify-center mx-auto pt-10 pb-20">
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M105.401 42.9002V77.1C105.401 82.7 102.401 87.9002 97.551 90.7502L67.851 107.9C63.001 110.7 57.0008 110.7 52.1008 107.9L22.4008 90.7502C17.5508 87.9502 14.5508 82.75 14.5508 77.1V42.9002C14.5508 37.3002 17.5508 32.1 22.4008 29.25L52.1008 12.1C56.9508 9.3 62.951 9.3 67.851 12.1L97.551 29.25C102.401 32.1 105.401 37.2502 105.401 42.9002Z"
            fill="#FFAA37"
          />
          <path
            d="M60 68.75C57.95 68.75 56.25 67.05 56.25 65V38.75C56.25 36.7 57.95 35 60 35C62.05 35 63.75 36.7 63.75 38.75V65C63.75 67.05 62.05 68.75 60 68.75Z"
            fill="#7F3A0F"
          />
          <path
            d="M60 86.2542C59.35 86.2542 58.7 86.1041 58.1 85.8541C57.45 85.6041 56.9499 85.2543 56.4499 84.8043C55.9999 84.3043 55.65 83.7542 55.35 83.1542C55.1 82.5542 55 81.9042 55 81.2542C55 79.9542 55.4999 78.6541 56.4499 77.7041C56.9499 77.2541 57.45 76.9043 58.1 76.6543C59.95 75.8543 62.1501 76.3041 63.5501 77.7041C64.0001 78.2041 64.3499 78.7042 64.5999 79.3542C64.8499 79.9542 65 80.6042 65 81.2542C65 81.9042 64.8499 82.5542 64.5999 83.1542C64.3499 83.7542 64.0001 84.3043 63.5501 84.8043C62.6001 85.7543 61.35 86.2542 60 86.2542Z"
            fill="#7F3A0F"
          />
        </svg>

        <div className="space-y-2">
          <h1 className="text-purple-brand3 font-medium text-2xl text-center">
            {idType} Validation Failed
          </h1>

          <div className="text-base text-gray-brand7 space-y-2">
            <p>The details listed below do not match with those on your NIN:</p>
            <div className="space-y-1">
              {failedFields.map((field) => (
                <div key={field} className="flex items-center gap-2">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5306 11.8703C12.5739 11.9136 12.6083 11.9651 12.6318 12.0217C12.6552 12.0784 12.6673 12.1391 12.6673 12.2004C12.6673 12.2617 12.6552 12.3224 12.6318 12.3791C12.6083 12.4357 12.5739 12.4872 12.5306 12.5306C12.4872 12.5739 12.4357 12.6083 12.3791 12.6318C12.3224 12.6552 12.2617 12.6673 12.2004 12.6673C12.1391 12.6673 12.0784 12.6552 12.0217 12.6318C11.9651 12.6083 11.9136 12.5739 11.8703 12.5306L8.00065 8.66036L4.13103 12.5306C4.04347 12.6181 3.92471 12.6673 3.80088 12.6673C3.67705 12.6673 3.5583 12.6181 3.47074 12.5306C3.38318 12.443 3.33398 12.3242 3.33398 12.2004C3.33398 12.0766 3.38318 11.9578 3.47074 11.8703L7.34094 8.00065L3.47074 4.13103C3.38318 4.04347 3.33398 3.92471 3.33398 3.80088C3.33398 3.67705 3.38318 3.5583 3.47074 3.47074C3.5583 3.38318 3.67705 3.33398 3.80088 3.33398C3.92471 3.33398 4.04347 3.38318 4.13103 3.47074L8.00065 7.34094L11.8703 3.47074C11.9578 3.38318 12.0766 3.33398 12.2004 3.33398C12.3242 3.33398 12.443 3.38318 12.5306 3.47074C12.6181 3.5583 12.6673 3.67705 12.6673 3.80088C12.6673 3.92471 12.6181 4.04347 12.5306 4.13103L8.66036 8.00065L12.5306 11.8703Z"
                      fill="#E6544F"
                    />
                  </svg>
                  {field}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 w-full gap-4">
          <Link href="/" className={cn(buttonVariants(), 'w-full')}>
            Modify details
          </Link>
          <Link href="/" className={cn(buttonVariants({ variant: 'outline' }), 'w-full')}>
            Verify Manually
          </Link>
        </div>
      </div>
    </ModalNew>
  );
};

export default IdentityValidationFailedModal;
