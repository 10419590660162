import React from 'react';
import ModalNew from '../atom/modal-new';
import { Button } from '../atom/button';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '@/components/atom/otp-input';
import { Label } from '../atom/label';
import useCreateWalletPinMutation from '@/hooks/react-query/wallet/mutations/useCreateWalletPinMutation';
import { useToast } from '@/hooks/use-toast';

const CreateWalletPinModal = () => {
  const { toast } = useToast();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const open = searchParams.get('wallet-activation') === 'set-wallet-pin';
  const [pin, setPin] = React.useState('');
  const [confirmPin, setConfirmPin] = React.useState('');

  const isValid = pin && confirmPin && pin === confirmPin;

  const createWalletPinMutation = useCreateWalletPinMutation();

  const handleCreate = () => {
    createWalletPinMutation.mutate(
      { pin },
      {
        onSuccess(res) {
          if (res.succeeded) {
            router.replace(pathname + '?wallet-activation=set-transaction-pin');
          } else {
            toast({
              variant: 'destructive',
              title: 'Failed',
              description: res.message || 'Unable to create pin',
            });
          }
        },
        onError() {},
      }
    );
  };
  return (
    <ModalNew
      isOpen={open}
      onClose={() => router.replace(pathname)}
      title=""
      description=""
      classNames={{ content: 'w-full max-w-[534px]' }}
    >
      <div className="w-full max-w-[412px] flex flex-col gap-6 items-center justify-center mx-auto pt-5 pb-20">
        <div>
          <h1 className="text-2xl text-purple-brand3 font-medium">Create your wallet pin</h1>
          <p className="text-sm text-gray-brand6">
            Enter a 6 digit pin in order to restrict access to your wallet.
          </p>
        </div>
        <div>
          <Label>Enter wallet pin</Label>
          <InputOTP maxLength={6} value={pin} onChange={(value) => setPin(value)}>
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
            </InputOTPGroup>
            <InputOTPSeparator />
            <InputOTPGroup>
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        </div>
        <div>
          <Label>Confirm wallet pin</Label>
          <InputOTP maxLength={6} value={confirmPin} onChange={(value) => setConfirmPin(value)}>
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
            </InputOTPGroup>
            <InputOTPSeparator />
            <InputOTPGroup>
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        </div>

        <div className="mt-10 flex items-center justify-center gap-4 w-full">
          <Button variant="outline" className="w-1/3">
            Clear Form
          </Button>
          <Button
            className="w-2/3"
            disabled={!isValid}
            loading={createWalletPinMutation.isPending}
            onClick={handleCreate}
          >
            Save Wallet Pin
          </Button>
        </div>
      </div>
    </ModalNew>
  );
};

export default CreateWalletPinModal;
