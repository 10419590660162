'use client';

import React from 'react';
import ValidateEmailFailed from './ValidateEmailFailed';
import { Dialog, DialogContent } from '@/components/atom/dialog';
import Progressing from '@/components/atom/Progressing';
import ValidatePhoneForm from './ValidatePhoneForm';

const ValidatePhoneNumber = ({
  status,
  phone = '',
  openChange,
  onValidateOtp,
  processing,
  description,
  onResend,
}: {
  description?: string;
  processing: boolean;
  status: 'pending' | 'error' | 'idle' | 'success';
  phone: string;
  openChange: (v?: boolean) => void;
  onValidateOtp: (otp: string) => void;
  onResend: () => void;
}) => {
  return (
    <Dialog open={Boolean(phone)} onOpenChange={openChange}>
      <DialogContent className="w-full max-w-[572px]">
        {processing ? (
          <Progressing description={description && 'Verifying OTP'} />
        ) : (
          <>
            {status === 'error' ? (
              <ValidateEmailFailed onGoBack={openChange} />
            ) : (
              <ValidatePhoneForm phone={phone} onSubmit={onValidateOtp} onResend={onResend} />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ValidatePhoneNumber;
