import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { WALLET_ENDPOINTS } from '@/constants/apiPath';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface Payload {
  otp: string;
  idNumber: string;
}

const mutationFn = async (body: Payload) => {
  try {
    const res = await axiosClientInstance.post(
      WALLET_ENDPOINTS.validateNINOTP,
      { ...body },
      {
        baseURL: FUSION_API.apiMicroServiceWallet,
      }
    );
    return res.data?.data;
  } catch (err: any) {
    return handleError(err as AxiosError<ErrorPayload>);
  }
};

const useValidateNINOTPMutation = () => {
  return useMutation({
    mutationFn,
  });
};

export default useValidateNINOTPMutation;
