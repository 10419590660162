'use client';

import React from 'react';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { EditorContent } from '@tiptap/react';
import ModalNew from '@/components/atom/modal-new';
import { MODAL_PARAMS } from '@/constants';
import { Button } from '@/components/atom/button';
import { X } from 'lucide-react';
import Avatar from '@/components/atom/avatar';
import { useSession } from 'next-auth/react';
import useTipTapEditor from '@/hooks/use-tiptap-editor';
import MultipleMediaUploader from './MultipleMediaUploader';
import { CreatePostPayloadType, MediaType } from '@/types';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/atom/dropdown-menu';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { cn } from '@/lib/utils';
import SelectCommunityModal from './SelectCommunityModal';
import { ChevronLeft } from 'lucide-react';
import useCreatePostMutation from '@/hooks/react-query/posts/mutations/useCreatePostMutation';
import { useToast } from '@/hooks/use-toast';
import { useQueryClient } from '@tanstack/react-query';
import ConfirmPostCreationCloseModal from './ConfirmPostCreationCloseModal';
import useVideoThumbnail from '@/hooks/use-video-thumbnail';

const UpdatedCreatePostModal = () => {
  const { generateThumbnailsFromFiles } = useVideoThumbnail({});
  const router = useRouter();
  const params = useSearchParams();
  const pathname = usePathname();
  const session = useSession();
  const queryClient = useQueryClient();
  const imgInputRef = React.useRef<HTMLInputElement | null>(null);
  const videoInputRef = React.useRef<HTMLInputElement | null>(null);
  const createPostMutation = useCreatePostMutation();
  const { toast } = useToast();
  const { editor, value } = useTipTapEditor({
    placeholder: 'Click here to start typing...',
    className: '!min-h-full !h-full bg-pink-brand focus:border-none border-none',
    errorMessage: '',
  });

  const [payload, setPayload] = React.useState<Partial<CreatePostPayloadType>>({});
  const [selectCommunityIsOpen, setSelectCommunityIsOpen] = React.useState(false);
  const [discardPostIsOpen, setDiscardPostIsOpen] = React.useState(false);

  const onClose = () => {
    const hasInput =
      value.length > 0 || (payload?.media && payload?.media.length > 0) ? true : false;

    hasInput ? setDiscardPostIsOpen(true) : router.replace(pathname);
  };

  const handleNexButton = () => setSelectCommunityIsOpen(true);

  const handleSubmit = async (communityId: number) => {
    const data: CreatePostPayloadType = {
      ...payload,
      communityId,
      title: '',
      tags: [],
      taggedUsers: [],
      checking: true,
      allowComments: true,
      allowLikes: true,
      allowShares: true,
      content: value,
    };
    submitData(data);
  };

  // call API
  const submitData = async (data: CreatePostPayloadType) => {
    const videos = data?.media?.filter((med) => med.contentType.includes('video')) ?? [];
    const videoFiles = videos.map((v) => v.file);
    const videoThumbnailImages = await generateThumbnailsFromFiles(videoFiles as File[]);
    const videoThumbnails: MediaType[] = videoFiles.map((v, idx) => ({
      name: (v?.name?.split('.')[0] ?? '') + '.jpeg',
      base64: videoThumbnailImages[idx].split(',').pop() ?? '',
      contentType: 'image/jpeg',
    }));
    const media: MediaType[] =
      data?.media?.map((med) => ({
        base64: med.base64,
        contentType: med.contentType,
        name: med.name,
      })) ?? [];
    createPostMutation.mutate(
      { ...data, thumbNail: videoThumbnails, media },
      {
        onSuccess: (response) => {
          toast({
            description: response.message ?? 'Post created successfully',
            variant: 'success',
          });
          editor?.commands.clearContent();
          setPayload({});
          if (imgInputRef.current) {
            imgInputRef.current.value = ''; // Reset the file input
            imgInputRef.current.files = null; // Reset the file input
          }
          if (videoInputRef.current) {
            videoInputRef.current.value = ''; // Reset the file input
            videoInputRef.current.files = null; // Reset the file input
          }
          queryClient.invalidateQueries({
            queryKey: ['GetUserFeedPosts'],
          });
          queryClient.invalidateQueries({
            queryKey: ['GetPopularPosts'],
          });
          queryClient.invalidateQueries({
            queryKey: ['GetAllPosts'],
          });
          router.replace(pathname);
        },
        onError: (err: unknown) => {
          const error = err as unknown as string;
          toast({
            variant: 'destructive',
            description: error,
          });
        },
      }
    );
  };

  return (
    <ModalNew
      isOpen={params.get(MODAL_PARAMS.type) === MODAL_PARAMS.newPost}
      onClose={onClose}
      asDrawerOnMobile={true}
      classNames={{
        content:
          'w-screen md:w-[60vw] md:max-w-[800px] h-screen md:h-[80vh] max-h-screen md:max-h-[80vh] px-0 md:px-8 py-1 md:py-4 rounded-none md:rounded-lg',
        footer: 'border-t-0',
        title: 'p-3 md:p-6',
        childrenContainer: `p-3 md:p-6`,
      }}
      title={
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-1">
            <Button
              variant="ghost"
              className="block md:hidden p-0 appearance-none focus:outline-none outline-none"
              aria-label="Close"
              onClick={onClose}
            >
              <ChevronLeft size={16} />
            </Button>

            <h5 className="m-0 p-0 text-lg md:text-2xl font-medium text-purple-brand3">
              Create a Post
            </h5>
          </div>

          <Button
            variant="ghost"
            className="hidden md:flex h-[25px] w-[25px] p-0 appearance-none focus:outline-none"
            aria-label="Close"
            onClick={onClose}
          >
            <X size={20} />
          </Button>
        </div>
      }
      description=""
      hasCloseButton={false}
      footer={
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-5 items-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="p-0 rounded-full">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                      fill="#F07306"
                    />
                    <path
                      d="M5.93944 8.89232C6.67584 8.89232 7.27281 8.29535 7.27281 7.55895C7.27281 6.82255 6.67584 6.22559 5.93944 6.22559C5.20305 6.22559 4.60608 6.82255 4.60608 7.55895C4.60608 8.29535 5.20305 8.89232 5.93944 8.89232Z"
                      fill="#F3E9FE"
                    />
                    <path
                      d="M12.0778 8.89232C12.8141 8.89232 13.4111 8.29535 13.4111 7.55895C13.4111 6.82255 12.8141 6.22559 12.0778 6.22559C11.3414 6.22559 10.7444 6.82255 10.7444 7.55895C10.7444 8.29535 11.3414 8.89232 12.0778 8.89232Z"
                      fill="#F3E9FE"
                    />
                    <path
                      d="M12.372 11.2118C10.212 12.7318 7.81191 12.7051 5.65186 11.2118C5.38518 11.0251 5.17184 11.3451 5.33185 11.6384C5.99853 12.7051 7.30523 13.6918 9.01194 13.6918C10.7186 13.6918 12.0253 12.7318 12.692 11.6384C12.852 11.3451 12.6387 11.0251 12.372 11.2118Z"
                      fill="#F3E9FE"
                    />
                  </svg>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="p-0 border-none shadow-none z-[1500]" align="start">
                <Picker
                  data={data}
                  onEmojiSelect={(emoji: { native: string }) =>
                    editor?.commands.insertContent(emoji.native)
                  }
                  emojiSize={16}
                  theme="light"
                />
              </DropdownMenuContent>
            </DropdownMenu>

            <Button
              variant="ghost"
              size="icon"
              className="p-0 rounded-full"
              onClick={() => imgInputRef!.current?.click()}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.475 0.833008H15.025C14.3 0.833008 13.7667 1.13301 13.525 1.66634C13.3917 1.90801 13.3334 2.19134 13.3334 2.52467V4.97467C13.3334 6.03301 13.9667 6.66634 15.025 6.66634H17.475C17.8084 6.66634 18.0917 6.60801 18.3334 6.47467C18.8667 6.23301 19.1667 5.69967 19.1667 4.97467V2.52467C19.1667 1.46634 18.5334 0.833008 17.475 0.833008ZM18.2584 4.10801C18.175 4.19134 18.05 4.24967 17.9167 4.25801H16.7417V4.68301L16.75 5.41634C16.7417 5.55801 16.6917 5.67467 16.5917 5.77467C16.5084 5.85801 16.3834 5.91634 16.25 5.91634C15.975 5.91634 15.75 5.69134 15.75 5.41634V4.24967L14.5834 4.25801C14.3084 4.25801 14.0834 4.02467 14.0834 3.74967C14.0834 3.47467 14.3084 3.24967 14.5834 3.24967L15.3167 3.25801H15.75V2.09134C15.75 1.81634 15.975 1.58301 16.25 1.58301C16.525 1.58301 16.75 1.81634 16.75 2.09134L16.7417 2.68301V3.24967H17.9167C18.1917 3.24967 18.4167 3.47467 18.4167 3.74967C18.4084 3.89134 18.35 4.00801 18.2584 4.10801Z"
                  fill="#601E8A"
                />
                <path
                  d="M7.49993 8.65026C8.5953 8.65026 9.48327 7.76229 9.48327 6.66693C9.48327 5.57156 8.5953 4.68359 7.49993 4.68359C6.40457 4.68359 5.5166 5.57156 5.5166 6.66693C5.5166 7.76229 6.40457 8.65026 7.49993 8.65026Z"
                  fill="#601E8A"
                />
                <path
                  d="M17.475 6.66699H17.0833V10.5087L16.975 10.417C16.325 9.85866 15.275 9.85866 14.625 10.417L11.1583 13.392C10.5083 13.9503 9.45829 13.9503 8.80829 13.392L8.52496 13.1587C7.93329 12.642 6.99163 12.592 6.32496 13.042L3.20829 15.1337C3.02496 14.667 2.91663 14.1253 2.91663 13.492V6.50866C2.91663 4.15866 4.15829 2.91699 6.50829 2.91699H13.3333V2.52533C13.3333 2.19199 13.3916 1.90866 13.525 1.66699H6.50829C3.47496 1.66699 1.66663 3.47533 1.66663 6.50866V13.492C1.66663 14.4003 1.82496 15.192 2.13329 15.8587C2.84996 17.442 4.38329 18.3337 6.50829 18.3337H13.4916C16.525 18.3337 18.3333 16.5253 18.3333 13.492V6.47533C18.0916 6.60866 17.8083 6.66699 17.475 6.66699Z"
                  fill="#601E8A"
                />
              </svg>
            </Button>

            <Button
              variant="ghost"
              size="icon"
              className="p-0 rounded-full"
              onClick={() => videoInputRef!.current?.click()}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12.275 1.66699H7.72498V5.30033H12.275V1.66699Z" fill="#B12924" />
                <path
                  d="M13.525 1.66699V5.30033H18.225C17.8 3.00866 16.1084 1.67533 13.525 1.66699Z"
                  fill="#B12924"
                />
                <path
                  d="M1.66663 6.55078V13.4924C1.66663 16.5258 3.47496 18.3341 6.50829 18.3341H13.4916C16.525 18.3341 18.3333 16.5258 18.3333 13.4924V6.55078H1.66663ZM12.0333 13.4841L10.3 14.4841C9.93329 14.6924 9.57496 14.8008 9.24163 14.8008C8.99163 14.8008 8.76663 14.7424 8.55829 14.6258C8.07496 14.3508 7.80829 13.7841 7.80829 13.0508V11.0508C7.80829 10.3174 8.07496 9.75078 8.55829 9.47578C9.04163 9.19245 9.65829 9.24245 10.3 9.61745L12.0333 10.6174C12.675 10.9841 13.025 11.5008 13.025 12.0591C13.025 12.6174 12.6666 13.1091 12.0333 13.4841Z"
                  fill="#B12924"
                />
                <path
                  d="M6.47502 1.66699C3.89169 1.67533 2.20002 3.00866 1.77502 5.30033H6.47502V1.66699Z"
                  fill="#B12924"
                />
              </svg>
            </Button>
          </div>

          <Button type="button" className="" disabled={!value.trim()} onClick={handleNexButton}>
            Next
          </Button>
        </div>
      }
    >
      <div className="w-full h-full bg-pink-brand rounded-sm flex flex-col overflow-y-hidden">
        <div className="w-full h-full flex flex-col gap-4 md:gap-5 p-3 md:p-6 pb-0 overflow-y-auto">
          <div className="flex w-fit gap-2 font-semibold items-center">
            <Avatar
              fallbackText={session?.data?.user?.name ?? ''}
              className="h-8 w-8"
              src={session?.data?.user?.avatarUrl || ''}
              isOnline
            />
            <span className="text-sm font-medium text-black-brand">
              {session?.data?.user?.name}
            </span>
          </div>

          <div className="h-full p-0">
            <EditorContent editor={editor} />
          </div>
        </div>

        <div
          className={cn(
            `w-full pb-4 md:pb-6 pt-3 md:pt-4 px-4 md:px-6 shadow-createPost border-t-2 border-t-[#F3E9FE]`,
            payload?.media?.length && payload?.media?.length > 0 ? `` : `h-0.5`
          )}
        >
          <MultipleMediaUploader
            imageRef={imgInputRef}
            videoRef={videoInputRef}
            onFileSelected={(v) => {
              setPayload((prev) => ({ ...prev, media: v }));
            }}
          />
        </div>
      </div>

      <SelectCommunityModal
        open={selectCommunityIsOpen}
        onOpenChange={() => setSelectCommunityIsOpen(!selectCommunityIsOpen)}
        onFinishPost={handleSubmit}
        loading={createPostMutation.isPending}
      />

      <ConfirmPostCreationCloseModal
        isOpen={discardPostIsOpen}
        onDiscardCreation={() => router.replace(pathname)}
        onContinueCreation={() => setDiscardPostIsOpen(false)}
      />
    </ModalNew>
  );
};

export default UpdatedCreatePostModal;
