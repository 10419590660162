import React from 'react';
import ModalNew from '../atom/modal-new';
import { buttonVariants } from '../atom/button';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

const IdentityValidationSuccessModal = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const open = searchParams.get('wallet-activation') === 'validate-success';
  const idType = searchParams.get('idType');

  return (
    <ModalNew
      isOpen={open}
      onClose={() => router.replace(pathname)}
      title=""
      description=""
      classNames={{ content: 'w-full max-w-[534px]' }}
    >
      <div className="w-full max-w-[374px] flex flex-col gap-6 items-center justify-center mx-auto pt-10 pb-20">
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 75C13.95 75 5 83.95 5 95C5 98.75 6.05001 102.3 7.90001 105.3C11.35 111.1 17.7 115 25 115C32.3 115 38.65 111.1 42.1 105.3C43.95 102.3 45 98.75 45 95C45 83.95 36.05 75 25 75ZM34.85 93.3499L24.2 103.2C23.5 103.85 22.55 104.2 21.65 104.2C20.7 104.2 19.75 103.85 19 103.1L14.05 98.15C12.6 96.7 12.6 94.3 14.05 92.85C15.5 91.4 17.9 91.4 19.35 92.85L21.75 95.2499L29.75 87.85C31.25 86.45 33.65 86.5499 35.05 88.0499C36.45 89.5499 36.35 91.9499 34.85 93.3499Z"
            fill="#FF951C"
          />
          <path
            d="M88.7497 35.2498C87.5497 35.0498 86.2997 34.9999 84.9997 34.9999H34.9997C33.5997 34.9999 32.2497 35.0999 30.9497 35.2999C31.6497 33.8999 32.6497 32.6 33.8497 31.4L50.0997 15.1C56.9497 8.3 68.0497 8.3 74.8997 15.1L83.6497 23.9498C86.8497 27.0998 88.5497 31.0998 88.7497 35.2498Z"
            fill="#FF951C"
          />
          <path
            d="M110 60V85C110 100 100 110 85 110H38.15C39.7 108.7 41.05 107.1 42.1 105.3C43.95 102.3 45 98.75 45 95C45 83.95 36.05 75 25 75C19 75 13.65 77.6499 10 81.7999V60C10 46.4 18.2 36.9 30.95 35.3C32.25 35.1 33.6 35 35 35H85C86.3 35 87.55 35.0499 88.75 35.2499C101.65 36.7499 110 46.3 110 60Z"
            fill="#601E8A"
          />
          <path
            d="M110 62.5H95C89.5 62.5 85 67 85 72.5C85 78 89.5 82.5 95 82.5H110"
            fill="#FF951C"
          />
        </svg>

        <div>
          <h1 className="text-purple-brand3 font-medium text-center text-2xl">
            {idType} Validation Successful
          </h1>

          <p className="text-base text-gray-brand7 text-center">
            Your {idType} has been validated successfully. You can now create your wallet and
            transaction pins.
          </p>
        </div>

        <div>
          <Link
            href={`${pathname}?wallet-activation=set-wallet-pin`}
            className={cn(buttonVariants(), 'w-full')}
          >
            Create a Wallet Pin to Access Your Bonus
          </Link>
        </div>
      </div>
    </ModalNew>
  );
};

export default IdentityValidationSuccessModal;
