import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { WALLET_ENDPOINTS } from '@/constants/apiPath';
import { ManualIDValidationModel } from '@/lib/models';
import { useMutation } from '@tanstack/react-query';

export interface IPayload extends ManualIDValidationModel {
  ninBase64StringImage: string;
  userBase64StringImage: string;
}

const mutationFn = async (body: IPayload) => {
  try {
    const payload = { ...body, dateOfBirth: new Date(body.dateOfBirth).toISOString() };
    const res = await axiosClientInstance.post(
      WALLET_ENDPOINTS.validateManualNIN,
      { ...payload },
      {
        baseURL: FUSION_API.apiMicroServiceWallet,
      }
    );
    return res.data?.data;
  } catch (err: any) {
    return err;
  }
};

const useValidateManualNINMutation = () => {
  return useMutation({
    mutationFn,
  });
};

export default useValidateManualNINMutation;
