import React from 'react';
import { Button } from '../atom/button';

const requirements = ['NIN or BVN', 'First name and last name', 'Date of birth', 'Gender'];

const ClaimBonus = ({ onProceed }: { onProceed: () => void }) => {
  return (
    <div className="w-full max-w-[400px] space-y-4 pt-10 pb-20 mx-auto">
      <h1 className="text-purple-brand3 font-medium text-2xl">
        Activate Your Wallet to Claim Your Bonus
      </h1>

      <p className="text-gray-brand7">
        Fusion will need to collect the following details according to new CBN regulations:
      </p>

      <div className="space-y-1 text-gray-brand7 mb-4">
        {requirements.map((requirement) => (
          <p key={requirement} className="flex items-center gap-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                stroke="#892DCC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {requirement}
          </p>
        ))}
      </div>

      <Button className="w-full mt-1" onClick={onProceed}>
        Proceed with Setup
      </Button>
    </div>
  );
};

export default ClaimBonus;
