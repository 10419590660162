'use client';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import RadioButton from '../atom/radio-button';
import { EditorContent } from '@tiptap/react';
import { Input } from '../atom/input';
import { Button } from '../atom/button';
import Img from '../atom/img';
import { ImageGalleryAdd } from '../icons';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/atom/select-input';
import InfiniteScrollContainer from '../InfiniteScrollContainer';
import { EventTypeOptions } from '@/constants';
import { CommunityType } from '@/types';
import useTipTapEditor from '@/hooks/use-tiptap-editor';
import { useSession } from 'next-auth/react';
import { ShopItemFormType } from './schemas';
import useMediaCapture from '@/hooks/use-media-capture';
import PictureTaker from '../PictureTaker';
import useGetUserCreatedCommunitiesQuery from '@/hooks/react-query/communities/queries/useGetUserCreatedCommunities';
import dynamic from 'next/dynamic';
import { Skeleton } from '../atom/skeleton';

const VideoPlayer = dynamic(() => import('@/components/atom/video-view'), {
  ssr: false,
  loading: () => <Skeleton className="h-20 w-full" />,
});

const ShopInfoForm = () => {
  const session = useSession();
  const form = useFormContext<ShopItemFormType>();
  const { editor, value } = useTipTapEditor({
    placeholder: 'Write a short description of your event (Maximum number of words: 150)',
    className: '!min-h-[103px]',
  });

  const {
    capturedImage,
    imgRef,
    handleFileInput,
    captureImage,
    videoRef,
    canvasRef,
    isCapturing,
    imageFile,
    setCapturedImage,
  } = useMediaCapture();

  const joinedCommunities = useGetUserCreatedCommunitiesQuery({
    UserId: session?.data?.user?.userId,
    Search: '',
  });

  React.useEffect(() => {
    if (value) {
      form.setValue('description', value);
    }
  }, [value]);

  React.useEffect(() => {
    if (capturedImage && imageFile) {
      form.setValue('base64', capturedImage);
      form.setValue('imageFile', imageFile);
    }
  }, [capturedImage]);

  return (
    <div className="w-full space-y-4 h-full max-h-full">
      <div className="">
        <label htmlFor="communityId">Community</label>
        <Controller
          name="communityId"
          control={form.control}
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger hasError={!!form.formState.errors?.communityId?.message}>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <InfiniteScrollContainer
                    onBottomReached={() =>
                      joinedCommunities.hasNextPage &&
                      !joinedCommunities.isFetching &&
                      joinedCommunities.fetchNextPage()
                    }
                  >
                    {joinedCommunities.data?.map((community: CommunityType) => (
                      <SelectItem key={community.id} value={`${community.id}`}>
                        {community.name}
                      </SelectItem>
                    ))}
                  </InfiniteScrollContainer>
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
        />
        {form.formState.errors?.communityId?.message && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {form.formState.errors?.communityId?.message}
          </p>
        )}
      </div>

      <div className="">
        <label htmlFor="image">Event banner</label>

        <div className="flex flex-col gap-4">
          <label
            htmlFor="file-upload"
            className=" cursor-pointer h-[216px] shadow-img bg-gray-brand4 relative flex items-center justify-center border-gray-300 rounded-lg"
          >
            {(capturedImage || form.watch('base64')) && (
              <>
                <Img
                  src={capturedImage || form.watch('base64') || ''}
                  alt="upload-media"
                  fill
                  className="absolute top-0 bottom-0 left-0 right-0 object-cover z-20"
                />

                <canvas ref={canvasRef} width={640} height={480} className="hidden" />
              </>
            )}
            <input
              type="file"
              id="file-upload"
              className="hidden h-full w-full absolute top-0 bottom-0 right-0 left-0"
              accept="image/*"
              ref={imgRef}
              onChange={handleFileInput}
            />

            <ImageGalleryAdd />
          </label>
          <div className="flex items-center justify-center w-full gap-4 rounded-sm">
            <Button
              type="button"
              variant="outline"
              className="w-full text-purple-brand bg-white"
              onClick={() => imgRef.current?.click()}
            >
              Browse Gallery
            </Button>
            <PictureTaker
              onCaptureImage={(imageSrc, file) => {
                form.setValue('base64', imageSrc as string);
                form.setValue('imageFile', file);
                setCapturedImage(imageSrc as string);
              }}
              type="landscape"
            />
          </div>
        </div>
      </div>

      <div className="w-full">
        <label className="text-sm font-medium" htmlFor="eventName">
          Name of event
        </label>
        <Input
          {...form.register('eventName')}
          errorMessage={form.formState.errors.eventName?.message}
          disabled={false}
        />
      </div>

      <div className="w-full mb-4">
        <label className="text-sm font-medium" htmlFor="username">
          Description
        </label>

        <EditorContent editor={editor} />
        <p className="text-end text-xs text-gray-brand">
          {150 - value?.trim()?.length} of 150 words left
        </p>
      </div>

      <div className="w-full">
        <label htmlFor="eventType">Event type</label>
        <div className="grid grid-cols-2 gap-4">
          <Controller
            name="eventType"
            control={form.control}
            render={({ field, fieldState: { error, isDirty } }) => (
              <>
                {EventTypeOptions.map((v) => (
                  <RadioButton
                    key={v.value}
                    id={v.value}
                    value={v.value}
                    label={v.label}
                    name={field.name}
                    onChange={field.onChange}
                    error={isDirty ? error?.message : ''}
                    checked={v.value === field.value}
                  />
                ))}
              </>
            )}
          />
        </div>
        {form.formState.errors?.eventType?.message && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {form.formState.errors?.eventType?.message}
          </p>
        )}
      </div>

      {isCapturing && (
        <div>
          <video ref={videoRef} autoPlay playsInline className="w-full h-64 object-cover" />
          <button onClick={captureImage}>Capture</button>
        </div>
      )}
    </div>
  );
};

export default ShopInfoForm;
