import React from 'react';
import WalletCard from './WalletCard';
import { Button, buttonVariants } from '../atom/button';
import Link from 'next/link';
import { cn } from '@/lib/utils';

const Welcome = ({ onClaim }: { onClaim: () => void }) => {
  return (
    <div className="md:w-full max-w-[421px] mx-auto pt-10 pb-20">
      <div className="flex flex-col gap-6 w-full mt-6 mx-auto max-w-[261px]">
        <div className="w-full flex items-center justify-center gap-4">
          <div className="relative w-full max-w-[284px]">
            <WalletCard />
          </div>
        </div>

        <h1 className="text-purple-brand3 font-medium text-center text-2xl">Welcome to Fusion</h1>

        <div className="w-full flex flex-col gap-4">
          <Button className="w-full" onClick={onClaim}>
            Claim Your Welcome Bonus Now!
          </Button>
          <Link href="/" className={cn(buttonVariants({ variant: 'outline' }), 'w-full')}>
            Explore Fusion
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
