import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { WALLET_ENDPOINTS } from '@/constants/apiPath';
import { WalletDetailsType } from '@/types';
import { useQuery } from '@tanstack/react-query';

const getWalletDetails = async (params: {
  Email_Or_WalletId: string;
}): Promise<WalletDetailsType> => {
  const res = await axiosClientInstance.get(WALLET_ENDPOINTS.getWalletDetails, {
    params,
    baseURL: FUSION_API.apiMicroServiceWallet,
  });
  return res.data.data;
};

const useGetWalletDetailsQuery = (params: { Email_Or_WalletId: string }) => {
  return useQuery({
    queryKey: ['GetUserWallet', { ...params }],
    queryFn: () => getWalletDetails(params),
    enabled: Boolean(params.Email_Or_WalletId),
  });
};

export default useGetWalletDetailsQuery;
