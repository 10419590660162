import React from 'react';
import { Label } from '../atom/label';
import { Input } from '../atom/input';
import { Controller, useForm } from 'react-hook-form';
import { GenderOptions, IdentificationTypes } from '@/constants';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/atom/select-input';
import RadioButton from '../atom/radio-button';
import { getDaysOfMonth, getMonths, getYearsArray } from '@/lib/days';
import { ValidateIDFormModel } from '@/lib/models';
import { validateIDSchema } from '@/lib/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { GenderEnum, IdentityEnum } from '@/utils/enums';
import { Button } from '../atom/button';
import dayjs from 'dayjs';
import useVerifyNINMutation from '@/hooks/react-query/wallet/mutations/useVerifyNINMutation';
import Validating from './Validating';
import { cn, extractLastTwoPhoneDigits } from '@/lib/utils';
import ValidatePhoneNumber from '../auth/ValidatePhoneNumber';
import useVerifyBVNMutation from '@/hooks/react-query/wallet/mutations/useVerifyBVNMutation';
import { useSession } from 'next-auth/react';
import { isValid } from 'date-fns';
import { useToast } from '@/hooks/use-toast';
import useValidateNINOTPMutation from '@/hooks/react-query/wallet/mutations/useValidateNINOTPMutation';
import useValidateBVNOTPMutation from '@/hooks/react-query/wallet/mutations/useValidateBVNOTPMutation';
import { usePathname, useRouter } from 'next/navigation';

interface ValidateResponsePayload {
  succeeded: boolean;
  message: string;
  errors: any;
  data: string;
}

const ValidateIdForm = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { data } = useSession();
  const { toast } = useToast();
  const [phone, setPhone] = React.useState('');
  const [dates, setDates] = React.useState<{
    month?: string;
    day?: string;
    year?: string;
    initial?: boolean;
  }>({});
  const [status, setStatus] = React.useState<'error' | 'success' | 'pending' | 'idle'>('idle');
  const verifyNINMutation = useVerifyNINMutation();
  const validateBVNMutation = useVerifyBVNMutation();
  const validateNINOtp = useValidateNINOTPMutation();
  const validateBVNOtp = useValidateBVNOTPMutation();
  const form = useForm<ValidateIDFormModel>({
    resolver: yupResolver(validateIDSchema),
    defaultValues: {
      dateOfBirth: undefined,
      gender: '',
      bvn: '',
      nin: '',
      firstName: '',
      lastName: '',
    },
    mode: 'onChange',
  });

  const idTypeField = form.watch('idType');
  const handleDateChange = (value: any, label: 'month' | 'day' | 'year') => {
    if (value) {
      setDates((prev) => ({ ...prev, [label]: value }));
    }
  };
  React.useEffect(() => {
    // Check if all fields (month, day, year) have values
    if (dates?.month && dates?.day && dates?.year && !dates.initial) {
      // Create a new Date object
      const selectedDate: Date = new Date(`${dates.month} ${dates.day}, ${dates.year}`);

      // Check if the date is valid
      form.setValue('dateOfBirth', dayjs(selectedDate).format('YYYY-MM-DD') as unknown as Date, {
        shouldValidate: true,
      });
    }
  }, [dates, form]);

  React.useEffect(() => {
    if (data?.user) {
      const { name, dateOfBirth, gender } = data.user;
      form.setValue('firstName', name?.split(' ')[0] ?? '');
      form.setValue('lastName', name?.split(' ')[1] ?? '');
      if (gender) {
        form.setValue('gender', `${GenderEnum[gender as keyof typeof GenderEnum]}`);
      }

      if (isValid(dateOfBirth)) {
        const dateValue = dayjs(dateOfBirth).format('YYYY-MM-DD') as unknown as Date;
        form.setValue('dateOfBirth', dateValue);

        const month = new Date(dateOfBirth).toLocaleString('default', {
          month: 'long',
        });

        const day = new Date(dateOfBirth).toLocaleString('default', {
          day: 'numeric',
        });

        const year = new Date(dateOfBirth).getFullYear().toString();
        setDates((prev) => ({ ...prev, month, day, year, initial: true }));
      }
    }
  }, [data?.user]);
  const onValidate = (otp: string) => {
    if (idTypeField === `${IdentityEnum.NIN}`) {
      validateNINOtp.mutate(
        {
          otp,
          idNumber: form.getValues().nin ?? '',
        },
        {
          onSuccess: (payload) => {
            setPhone('');
            router.replace(`${pathname}?wallet-activation=validate-success&idType=${idTypeField}`);
          },
          onError: (err: any) => {
            setStatus('error');
          },
        }
      );
    } else if (idTypeField === `${IdentityEnum.BVN}`) {
      const bvn = form.getValues().bvn;
      if (bvn) {
        validateBVNOtp.mutate(
          { otp, idNumber: form.getValues().bvn ?? '' },
          {
            onSuccess: (payload) => {
              setPhone('');
              router.replace(
                `${pathname}?wallet-activation=validate-success&idType=${idTypeField}`
              );
            },
            onError: (err: any) => {
              setStatus('error');
            },
          }
        );
      } else {
      }
    }
  };
  const onSubmit = (values: ValidateIDFormModel) => {
    const payload: Omit<ValidateIDFormModel, 'idType'> = {
      lastName: values.lastName,
      firstName: values.firstName,
      dateOfBirth: values.dateOfBirth,
      gender: values.gender,
    };
    if (idTypeField === `${IdentityEnum.NIN}`) {
      payload['nin'] = values.nin;
      verifyNINMutation.mutate(payload, {
        onError(e) {
          if (`${e}`.toLowerCase() === 'success') {
            if (data?.user) {
              const { mobileNumber } = data.user;
              if (mobileNumber) {
                setPhone(mobileNumber);
              }
            }
          } else {
            form.setError('nin', { type: 'nin', message: `${e}` });
          }
        },
        onSuccess(res) {
          if (res.succeeded) {
            setPhone('89');
          } else if (res.response?.data?.succeeded) {
            setPhone('89');
          } else {
            setPhone('');
            form.setError('nin', {
              type: 'nin',
              message: res.response?.data?.message || 'Unable to validate NIN',
            });
          }
        },
      });
    } else if (idTypeField === `${IdentityEnum.BVN}`) {
      payload['bvn'] = values.bvn;
      validateBVNMutation.mutate(payload, {
        onError(e: any) {
          if (e.status === 400) {
            form.setError('bvn', {
              type: 'bvn',
              message: e.response?.data?.message || 'Invalid BVN provided',
            });
          } else if (e.response?.data?.succeeded) {
            if (data?.user) {
              const { mobileNumber } = data.user;
              if (mobileNumber) {
                setPhone(mobileNumber);
              }
            }
          }
        },
        onSuccess(res) {
          const payload: ValidateResponsePayload = res.response.data;
          if (payload.succeeded) {
            setPhone(extractLastTwoPhoneDigits(payload.data));
            setStatus('success');
          } else {
            form.setError('bvn', {
              type: 'bvn',
              message: payload?.message || 'Invalid BVN provided',
            });
          }
        },
      });
    }
  };

  return (
    <div className="w-full max-w-[320px] min-h-[318px]  mx-auto relative">
      <div
        className={cn(
          'absolute top-0 bottom-0 left-0 right-0 bg-white mt-6',
          !(verifyNINMutation.isPending || validateBVNMutation.isPending) ? 'hidden' : 'block'
        )}
      >
        <Validating
          text={`Validating ${
            verifyNINMutation.isPending
              ? 'NIN details'
              : validateBVNMutation.isPending
              ? 'BVN details'
              : ''
          }`}
        />
      </div>
      <div
        className={cn(
          'space-y-4 pb-10',
          verifyNINMutation.isPending || validateBVNMutation.isPending ? 'hidden' : 'block'
        )}
      >
        <h1 className="text-purple-brand3 font-medium text-2xl">Validate your ID</h1>

        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div className="w-full">
              <Label htmlFor="firstName">First name</Label>
              <Controller
                control={form.control}
                name="firstName"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={error?.message}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Label htmlFor="lastName">Last name</Label>
              <Controller
                control={form.control}
                name="lastName"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="w-full">
            <label htmlFor="gender">Gender</label>
            <div className="grid grid-cols-2 gap-4">
              <Controller
                name="gender"
                control={form.control}
                render={({ field, fieldState: { error, isDirty } }) => (
                  <>
                    {GenderOptions.slice(0, 2).map((v) => (
                      <RadioButton
                        key={v.value}
                        id={v.label}
                        value={v.value}
                        label={v.label}
                        name={field.name}
                        onChange={field.onChange}
                        error={isDirty ? error?.message : ''}
                        checked={v.value.toString() === field.value}
                      />
                    ))}
                  </>
                )}
              />
            </div>
          </div>

          <div className="w-full">
            <label htmlFor="dateOfBirth">Date of birth</label>

            <div className="grid grid-cols-7 gap-4">
              <div className="col-span-3">
                <Select
                  onValueChange={(v) => handleDateChange(v || dates.month, 'month')}
                  value={dates.month}
                  defaultValue={dates.month}
                >
                  <SelectTrigger hasError={!!form.formState.errors?.dateOfBirth?.message}>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {getMonths().map((month) => (
                        <SelectItem key={month} value={month}>
                          {month}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="col-span-2">
                <Select onValueChange={(v) => handleDateChange(v, 'day')} value={dates.day}>
                  <SelectTrigger hasError={!!form.formState.errors?.dateOfBirth?.message}>
                    <SelectValue placeholder="" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {getDaysOfMonth().map((day: number) => (
                        <SelectItem key={day} value={`${day}`}>
                          {day}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>

              <div className="col-span-2">
                <Select onValueChange={(v) => handleDateChange(v, 'year')} value={dates.year}>
                  <SelectTrigger hasError={!!form.formState.errors?.dateOfBirth?.message}>
                    <SelectValue placeholder="" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {getYearsArray().map((year: number) => (
                        <SelectItem key={year} value={`${year}`}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {form.formState.errors?.dateOfBirth?.message && (
              <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                {form.formState.errors?.dateOfBirth?.message}
              </p>
            )}
          </div>

          <div className="w-full">
            <label htmlFor="idType">ID Type</label>
            <div className="grid grid-cols-2 gap-4">
              <Controller
                name="idType"
                control={form.control}
                render={({ field, fieldState: { error, isDirty } }) => (
                  <>
                    {IdentificationTypes.map((v) => (
                      <RadioButton
                        key={v.value}
                        id={v.label}
                        value={v.value}
                        label={v.label}
                        name={field.name}
                        onChange={field.onChange}
                        error={isDirty ? error?.message : ''}
                        checked={v.value.toString() === field.value}
                      />
                    ))}
                  </>
                )}
              />
            </div>
          </div>

          {idTypeField === `${IdentityEnum.NIN}` && (
            <div className="w-full">
              <Label htmlFor="nin">National Identification Number</Label>
              <Controller
                control={form.control}
                name="nin"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={error?.message}
                    placeholder="Enter your National Identification Number"
                  />
                )}
              />
            </div>
          )}

          {idTypeField === `${IdentityEnum.BVN}` && (
            <div className="w-full">
              <Label htmlFor="bvn">Bank Verification Number</Label>
              <Controller
                control={form.control}
                name="bvn"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={error?.message}
                    placeholder="Enter your Bank Verification Number"
                  />
                )}
              />
            </div>
          )}

          <div className="flex items-center gap-4 pt-6 space-y-4">
            <Button
              className="w-full"
              variant="outline"
              type="button"
              onClick={() => {
                form.reset();
                setDates({});
              }}
            >
              Clear Form
            </Button>
            <Button
              className="w-full !mt-0"
              type="submit"
              disabled={!form.formState.isValid}
              loading={verifyNINMutation.isPending || validateBVNMutation.isPending}
            >
              Validate Details
            </Button>
          </div>
        </form>
      </div>

      <ValidatePhoneNumber
        processing={validateBVNOtp.isPending || validateNINOtp.isPending}
        status={status}
        phone={phone}
        openChange={() => setPhone('')}
        onValidateOtp={onValidate}
        onResend={() => {}}
      />
    </div>
  );
};

export default ValidateIdForm;
