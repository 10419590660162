import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { WALLET_ENDPOINTS } from '@/constants/apiPath';
import { ValidateIDFormModel } from '@/lib/models';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const mutationFn = async (body: Omit<ValidateIDFormModel, 'idType'>) => {
  try {
    const payload = { ...body, dateOfBirth: new Date(body.dateOfBirth).toISOString() };
    const res = await axiosClientInstance.post(
      WALLET_ENDPOINTS.verifyNIN,
      { ...payload },
      {
        baseURL: FUSION_API.apiMicroServiceWallet,
      }
    );
    return res.data;
  } catch (err: any) {
    return err;
  }
};

const useVerifyNINMutation = () => {
  return useMutation({
    mutationFn,
  });
};

export default useVerifyNINMutation;
