import React from 'react';
import ModalNew from '../atom/modal-new';
import Welcome from './Welcome';
import ClaimBonus from './ClaimBonus';
import { cn } from '@/lib/utils';
import ValidateIdForm from './ValidateIdForm';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

const WalletActivationModal = () => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const opened = searchParams.get('wallet-activation') === 'validate-id';
  const [step, setStep] = React.useState(1);
  return (
    <ModalNew
      isOpen={opened}
      onClose={() => router.replace(pathname)}
      title=""
      description=""
      classNames={{ content: cn('w-full', step === 2 && ' max-w-[560px]') }}
    >
      {step === 1 && <Welcome onClaim={() => setStep(2)} />}
      {step === 2 && <ClaimBonus onProceed={() => setStep(3)} />}
      {step === 3 && <ValidateIdForm />}
    </ModalNew>
  );
};

export default WalletActivationModal;
