'use client';

import React, { useMemo } from 'react';
import SignInModal from './auth/SigninModal';
import PreCreateModal from '@/molecules/PreCreate/PreCreateModal';
import CreatePostModal from '@/molecules/CreatePostModal';
import UpdatedCreatePostModal from '@/molecules/UpdatedCreatePostModal';
import CreateCommunityModal from '@/molecules/CreateCommunityModal';
import CreateCommunitySuccessModal from '@/molecules/CreateCommunitySuccessModal';
import ShareModal from '@/components/share/share-modal';
import CreateShopItemModal from '@/components/shop/CreateShopItemModal';
import { useSearchParams } from 'next/navigation';
import useCommunityStore from '@/hooks/store/use-community-store';
import useAppStore from '@/hooks/use-app-store';
import CreateShopItemSuccessModal from './shop/CreateShopItemSuccessModal';
import useShopStore from '@/hooks/store/use-shop-store';
import EditEventTitleModal from './shop/EditEventTitleModal';
import EditEventDetailsModal from './shop/EditEventDetailsModal';
import { MODAL_PARAMS } from '@/constants';
import useModalControl from '@/hooks/store/use-modal-control';
import JoinPrivateCommunity from './JoinPrivateCommunity';
import WalletActivationModal from './wallet-activation/WalletActivationModal';
import IdentityValidationSuccessModal from './wallet-activation/IdentityValidationSuccessModal';
import IdentityValidationFailedModal from './wallet-activation/IdentityValidationFailedModal';
import CreateTransactionPinModal from './wallet-activation/CreateTransactionPinModal';
import CreateWalletPinModal from './wallet-activation/CreateWalletPinModal';
import WalletSetupSuccessful from './wallet-activation/WalletSetupSuccessful';
import { useSession } from 'next-auth/react';
import ManualValidateIdForm from './wallet-activation/ManualValidateIdForm';
import ManualNINDetailsSubmitted from './wallet-activation/ManualNINDetailsSubmitted';

const Modals = () => {
  const session = useSession();
  const user = session.data?.user;
  const searchParams = useSearchParams();
  const { communitySuccess } = useCommunityStore();
  const { shopSuccess } = useShopStore();
  const { showShareModal } = useAppStore((state) => state);
  const { modalType } = useModalControl();
  const showWalletActivation = useMemo(() => {
    return (
      session?.status !== 'loading' &&
      session.status === 'authenticated' &&
      (typeof user?.hasActiveWallet === 'string'
        ? user?.hasActiveWallet === 'false'
        : typeof user?.hasActiveWallet === 'undefined'
        ? false
        : user?.hasActiveWallet)
    );
  }, [user, session.status]);

  // console.log({
  //   showWalletActivation,
  //   type: typeof user?.hasActiveWallet === 'string',
  //   isActive: user?.hasActiveWallet === 'false',
  // });

  return (
    <>
      {searchParams.get('ref') === 'signin' && <SignInModal />}
      {searchParams.get('ref') === 'create' && !searchParams.get('type') && <PreCreateModal />}
      {searchParams.get('type') === 'community' && !communitySuccess && <CreateCommunityModal />}
      {searchParams.get('type') === 'store-item' && !shopSuccess && <CreateShopItemModal />}
      {searchParams.get('type') === 'post' && <CreatePostModal />}
      {searchParams.get(MODAL_PARAMS.type) === MODAL_PARAMS.newPost && <UpdatedCreatePostModal />}
      {searchParams.get(MODAL_PARAMS.type) === MODAL_PARAMS.eventTitle && <EditEventTitleModal />}
      {searchParams.get(MODAL_PARAMS.type) === MODAL_PARAMS.eventDetails && (
        <EditEventDetailsModal />
      )}
      {/* state modals */}
      {communitySuccess && <CreateCommunitySuccessModal />}
      {shopSuccess && <CreateShopItemSuccessModal />}
      {showShareModal && <ShareModal />}
      {modalType.type === 'joinPrivateCommunity' && modalType.isOpen && <JoinPrivateCommunity />}
      {showWalletActivation && (
        <>
          {searchParams.get('wallet-activation') === 'validate-id' && <WalletActivationModal />}
          {searchParams.get('wallet-activation') === 'validate-success' && (
            <IdentityValidationSuccessModal />
          )}
          {searchParams.get('wallet-activation') === 'failed' && <IdentityValidationFailedModal />}
          {searchParams.get('wallet-activation') === 'set-transaction-pin' && (
            <CreateTransactionPinModal />
          )}
          {searchParams.get('wallet-activation') === 'set-wallet-pin' && <CreateWalletPinModal />}
          {searchParams.get('wallet-activation') === 'successful' && <WalletSetupSuccessful />}
          {searchParams.get('wallet-activation') === 'validate-manual-nin' && (
            <ManualValidateIdForm />
          )}
          {searchParams.get('wallet-activation') === 'manual-details-submitted' && (
            <ManualNINDetailsSubmitted />
          )}
        </>
      )}
    </>
  );
};

export default Modals;
