import React from 'react';
import Progressing from '../atom/Progressing';

const Validating = ({ text = 'Validating your NIN details...' }: { text?: string }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <Progressing wrapperClassName="h-fit my-0 lg:my-0" />

      <p className="text-xl text-black-brand text-center">{text}</p>
    </div>
  );
};

export default Validating;
