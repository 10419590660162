import React from 'react';
import { buttonVariants } from '../atom/button';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import ModalNew from '../atom/modal-new';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

const ManualNINDetailsSubmitted = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const open = searchParams.get('wallet-activation') === 'manual-details-submitted';
  return (
    <ModalNew isOpen={open} onClose={() => router.push(pathname)} title="" description="">
      <div className="w-full md:max-w-[421px] mx-auto pt-10 pb-20">
        <div className="flex flex-col items-center justify-center gap-6 w-full mt-6 mx-auto max-w-[300px]">
          <div className="w-full flex items-center justify-center ">
            <div className="relative w-full max-w-[284px] flex items-center justify-center mx-auto">
              <svg
                width="120"
                height="120"
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M60 110C87.6142 110 110 87.6142 110 60C110 32.3858 87.6142 10 60 10C32.3858 10 10 32.3858 10 60C10 87.6142 32.3858 110 60 110Z"
                  fill="#027A48"
                />
                <path
                  d="M52.8997 77.898C51.8997 77.898 50.9497 77.498 50.2497 76.798L36.0997 62.648C34.6497 61.198 34.6497 58.798 36.0997 57.348C37.5497 55.898 39.9497 55.898 41.3997 57.348L52.8997 68.848L78.5997 43.148C80.0497 41.698 82.4497 41.698 83.8997 43.148C85.3497 44.598 85.3497 46.998 83.8997 48.448L55.5497 76.798C54.8497 77.498 53.8997 77.898 52.8997 77.898Z"
                  fill="#027A48"
                />
              </svg>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <h1 className="text-purple-brand3 font-medium text-center text-2xl">
              NIN Details Submitted
            </h1>
            <p className="text-base text-center text-gray-brand7">
              You have successfully initiated a manual validation process. NIN validation will be
              completed within 1 - 2 working days.
            </p>
          </div>

          <div className="w-full flex flex-col gap-4">
            <Link href="/wallet" className={cn(buttonVariants({ variant: 'outline' }), 'w-full')}>
              View Wallet
            </Link>
          </div>
        </div>
      </div>
    </ModalNew>
  );
};

export default ManualNINDetailsSubmitted;
