import React from 'react';
import WalletCardActive from './WalletCardActive';
import { buttonVariants } from '../atom/button';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import ModalNew from '../atom/modal-new';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

const WalletSetupSuccessful = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const open = searchParams.get('wallet-activation') === 'successful';
  return (
    <ModalNew isOpen={open} onClose={() => router.replace(pathname)} title="" description="">
      <div className="w-full md:max-w-[421px] mx-auto pt-10 pb-20">
        <div className="flex flex-col items-center justify-center gap-6 w-full mt-6 mx-auto max-w-[300px]">
          <div className="w-full flex items-center justify-center ">
            <div className="relative w-full max-w-[284px] mx-auto">
              <WalletCardActive />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <h1 className="text-purple-brand3 font-medium text-center text-2xl">
              Wallet Setup Successful
            </h1>
            <p className="text-base text-center text-gray-brand7">
              You have successfully setup your Fusion wallet.{' '}
            </p>
          </div>

          <div className="w-full flex flex-col gap-4">
            <Link href="/wallet" className={cn(buttonVariants({}), 'w-full')}>
              Fund My Wallet
            </Link>
            <Link
              href="/explore/public-communities"
              className={cn(buttonVariants({ variant: 'outline' }), 'w-full')}
            >
              Explore Communities
            </Link>
          </div>
        </div>
      </div>
    </ModalNew>
  );
};

export default WalletSetupSuccessful;
