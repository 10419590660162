import React from 'react';
import ModalNew from '../atom/modal-new';
import { Button } from '../atom/button';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { InputOTP, InputOTPSlot } from '@/components/atom/otp-input';
import { Label } from '../atom/label';
import useCreateWalletPinMutation from '@/hooks/react-query/wallet/mutations/useCreateWalletPinMutation';

const CreateTransactionPinModal = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const open = searchParams.get('wallet-activation') === 'set-transaction-pin';
  const [pin, setPin] = React.useState('');
  const [confirmPin, setConfirmPin] = React.useState('');
  const isValid = pin && confirmPin && pin === confirmPin;

  const createWalletPinMutation = useCreateWalletPinMutation();

  const handleCreate = () => {
    createWalletPinMutation.mutate(
      { pin, isTransaction: true },
      {
        onSuccess() {
          router.replace(pathname + '?wallet-activation=successful');
        },
        onError() {},
      }
    );
  };

  return (
    <ModalNew
      isOpen={open}
      onClose={() => router.replace(pathname)}
      title=""
      description=""
      classNames={{ content: 'w-full max-w-[534px]' }}
    >
      <div className="w-full max-w-[412px] flex flex-col gap-6 items-center justify-center mx-auto pt-5 pb-20">
        <div>
          <h1 className="text-2xl text-purple-brand3 font-medium">Create your transaction pin</h1>
          <p className="text-sm text-gray-brand6">
            Enter a 4 digit pin in order to restrict access to your wallet.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-1">
          <Label className="text-center">Enter transaction pin</Label>
          <InputOTP maxLength={4} value={pin} onChange={(value) => setPin(value)}>
            <InputOTPSlot mask className="rounded-full" index={0} />
            <InputOTPSlot mask className="rounded-full" index={1} />
            <InputOTPSlot mask className="rounded-full" index={2} />
            <InputOTPSlot mask className="rounded-full" index={3} />
          </InputOTP>
        </div>
        <div className="flex flex-col items-center justify-center gap-1">
          <Label className="text-center">Confirm transaction pin</Label>
          <InputOTP maxLength={4} value={confirmPin} onChange={(value) => setConfirmPin(value)}>
            <InputOTPSlot mask className="rounded-full" index={0} />
            <InputOTPSlot mask className="rounded-full" index={1} />
            <InputOTPSlot mask className="rounded-full" index={2} />
            <InputOTPSlot mask className="rounded-full" index={3} />
          </InputOTP>
        </div>

        <div className="mt-10 flex items-center justify-center gap-4 w-full">
          <Button variant="outline" className="w-1/3">
            Clear Form
          </Button>
          <Button
            className="w-2/3"
            disabled={!isValid}
            onClick={handleCreate}
            loading={createWalletPinMutation.isPending}
          >
            Save transaction pin
          </Button>
        </div>
      </div>
    </ModalNew>
  );
};

export default CreateTransactionPinModal;
