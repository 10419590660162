import React from 'react';
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/atom/dialog';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '@/components/atom/otp-input';
import Countdown from '@/components/atom/Countdown';
import { maskPhoneNumber } from '@/lib/utils';

const ValidatePhoneForm = ({
  phone,
  onSubmit,
  onResend,
}: {
  phone: string;
  onSubmit: (otp: string) => void;
  onResend: () => void;
}) => {
  const [value, setValue] = React.useState('');
  const [expired, setExpired] = React.useState(false);

  const handleSubmit = () => {
    if (value.length === 6) {
      onSubmit(value);
    }
  };

  React.useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <DialogHeader className="w-full max-w-[412px] mx-auto">
        <DialogTitle className="mt-10 mb-1 text-2xl font-medium text-purple-brand3 lg:mt-20">
          Confirm your phone number
        </DialogTitle>
        <DialogDescription className="text-sm text-gray-brand6 flex items-end h-fit">
          A verification code has been sent to{' '}
          {phone.length === 2 ? `*********${phone}` : maskPhoneNumber(phone)}
        </DialogDescription>
      </DialogHeader>
      <div className="w-full max-w-[412px] mx-auto mb-8 lg:mb-10 mt-8">
        <p className="text-sm font-medium text-gray-brand6">Enter Code</p>
        <InputOTP maxLength={6} value={value} onChange={(value) => setValue(value)}>
          <InputOTPGroup>
            <InputOTPSlot index={0} placeholder="0" mask />
            <InputOTPSlot index={1} placeholder="0" mask />
            <InputOTPSlot index={2} placeholder="0" mask />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot index={3} placeholder="0" mask />
            <InputOTPSlot index={4} placeholder="0" mask />
            <InputOTPSlot index={5} placeholder="0" mask />
          </InputOTPGroup>
        </InputOTP>
      </div>
      <DialogFooter className="mb-10 text-center sm:justify-center lg:mb-20">
        {expired ? (
          <button
            type="button"
            className="text-sm font-medium text-orange-brand ring-0"
            onClick={onResend}
          >
            Resend OTP
          </button>
        ) : (
          <Countdown onTimeout={() => setExpired(true)} />
        )}
      </DialogFooter>
    </>
  );
};

export default ValidatePhoneForm;
